<template>
  <section class="pa-3">
    <modal-loading :is-loading="loading" :message="loadingMessage" />
    <h2 class="mb-10">
      メイン写真設定
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on"> mdi-help-circle </v-icon>
        </template>
        <v-card width="400">
          <v-card-text>
            <p>
              トップページのスライダーに掲載する写真を10枚まで設定することができます。
            </p>
            <ol>
              <li>写真を選択ボタンを押して任意の写真を選択します。</li>
              <li>
                選択した写真が表示されるので、ドラッグすると表示順を変更することができます。
              </li>
              <li>設定ボタンを押して完了です。</li>
            </ol>
          </v-card-text>
        </v-card>
      </v-menu>
    </h2>

    <div class="d-flex justify-space-between">
      <v-btn
        large
        :disabled="selectedPhotos.length >= 1 ? false : true"
        class="mr-5"
        @click="handlePreview"
      >
        プレビュー
      </v-btn>
      <div>
        <v-btn
          large
          :disabled="selectedPhotos.length >= 1 ? false : true"
          outlined
          class="mr-12"
          @click="onCancel"
        >
          キャンセル
        </v-btn>
        <v-btn large dark color="#2196f3" @click="save"> 設定 </v-btn>
      </div>
    </div>
    <v-progress-linear
      height="1"
      color="#a5a5a5"
      rounded
      value="100"
      class="mt-5"
    />

    <div class="d-flex flex-wrap py-5">
      <v-card
        v-for="photo in selectedPhotos"
        :key="photo.id"
        width="170"
        class="ma-2"
      >
        <v-img :src="photo.image" width="170" height="170" />
        <v-card-actions>
          <v-spacer />
          <v-btn icon @click="selectPhotos(photo)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-for="n in 10 - selectedPhotos.length"
        :key="n"
        min-width="170"
        height="222"
        outlined
        class="d-flex flex-column justify-center align-center ma-2"
      >
        <v-icon>mdi-image-outline</v-icon>
        <p>画像</p>
      </v-card>
    </div>
    <v-progress-linear
      height="1"
      color="#a5a5a5"
      rounded
      value="100"
      class="mb-5"
    />
    <div>
      <div class="d-flex justify-space-between align-center mb-5">
        <!--
        <div class="d-flex align-center">
          <v-select
            :items="selectDate"
            outlined
          />
          <v-btn
            class="ml-2"
          >
            絞り込む
          </v-btn>
        </div>
-->
        <div class="d-flex col-5 pa-0">
          <v-text-field
            v-model="keyword"
            type="search"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            height="44px"
            @change="search"
          />
        </div>
      </div>

      <div class="d-flex flex-wrap mb-10">
        <v-card
          v-for="photo in photos"
          :key="photo.id"
          width="170"
          class="ma-2"
        >
          <v-img
            :src="photo.image"
            width="170"
            height="170"
            style="cursor: pointer"
            @click="selectPhotos(photo)"
          />
          <v-card-text
            class="pb-0"
            style="cursor: pointer"
            @click="selectPhotos(photo)"
          >
            {{ photo.title }}
          </v-card-text>
          <v-card-actions>
            <v-icon
              class="mr-3"
              :color="isSelected(photo.id) ? '#1452FA' : ''"
              @click="selectPhotos(photo)"
            >
              mdi-checkbox-marked
            </v-icon>
            <v-icon @click.self="handlePhotoPreview(photo)">
              mdi-magnify-plus-outline
            </v-icon>
          </v-card-actions>
        </v-card>
      </div>
      <v-pagination
        v-if="photos.length !== 0"
        v-model="currentPage"
        :length="lastPage"
        :total-visible="30"
      />
    </div>

    <v-dialog v-model="isPreview" min-width="400" hide-overlay>
      <v-card class="py-12 px-10">
        <div class="d-flex justify-space-around align-center">
          <v-card-title> プレビュー </v-card-title>
          <v-icon @click="handlePreview"> mdi-close </v-icon>
        </div>
        <v-card-actions class="d-flex justify-center">
          <v-icon
            :color="deviceSize === 'sp' ? '#1452FA' : ''"
            class="mx-5"
            @click="deviceSize = 'sp'"
          >
            mdi-cellphone-iphone
          </v-icon>
          <v-icon
            :color="deviceSize === 'pc' ? '#1452FA' : ''"
            class="mx-5"
            @click="deviceSize = 'pc'"
          >
            mdi-monitor
          </v-icon>
        </v-card-actions>
        <slider :photos="selectedPhotos" :device-type="deviceSize" />
      </v-card>
    </v-dialog>
    <v-dialog v-model="isDialog" width="600">
      <v-img
        :src="previewPhoto.image"
        contain
        max-width="600"
        max-height="800"
      />
    </v-dialog>
  </section>
</template>

<script>
import { getData, updateData } from "../../axios";
import ModalLoading from "../../components/ModalLoading.vue";
import Slider from "../../components/Slider.vue";

export default {
  name: "Index",

  components: {
    Slider,
    ModalLoading,
  },

  data() {
    return {
      isDialog: false,
      previewPhoto: "",
      isPreview: false,
      loading: false,
      loadingMessage: "",
      params: {
        page: 0,
        words: "",
        id: "",
        shot_at: "",
      },
      keyword: "",
      currentPage: 1,
      lastPage: 0,
      photos: [],
      backupSelectedPhotos: [],
      selectedPhotos: [],
      selectDate: ["全ての日付"],
      deviceSize: "pc",
    };
  },

  watch: {
    "$route.query"() {
      this.getPhotos();
    },
    currentPage() {
      if (this.params.page === Number(this.currentPage)) return;
      this.params.page = Number(this.currentPage);
      this.routerPush();
    },
  },

  created() {
    this.currentPage = Number(this.$route.query.page) || 1;
    this.params.page = Number(this.$route.query.page) || 1;
    this.getPhotos();
    this.getSelectedPhotosId();
  },

  methods: {
    isSelected(id) {
      return this.selectedPhotos.find((photo) => {
        return photo.id === id;
      });
    },
    search(keyword) {
      if (
        (!this.$route.query.words && !keyword) ||
        this.$route.query.words === keyword
      )
        return;
      this.params.words = keyword;
      // 検索後は1ページを表示
      this.params.page = 1;
      this.routerPush();
    },
    routerPush() {
      const params = Object.assign({}, this.params);
      // 空文字のクエリーを無くす：例）words=''
      Object.keys(params).forEach((key) => {
        if (params[key] === "") delete params[key];
      });
      this.$router.push({ query: params });
    },
    getPhotos() {
      if (Object.keys(this.$route.query).length !== 0) {
        const queries = Object.assign({}, this.$route.query);
        Object.keys(queries).forEach((q) => {
          if (q === "page") {
            this.params[q] = Number(queries[q]);
          } else {
            this.params[q] = queries[q];
          }
        });
      }
      // status=publishedをURLに表示せずに写真を取得するため
      const params = Object.assign({}, this.params);
      params.status = "published";
      getData("photos", params).then((res) => {
        this.photos = res.data;
        this.lastPage = res.last_page;
      });
    },
    getSelectedPhotosId() {
      getData("clients").then((res) => {
        const mainVisual = res.data.setting.mainVisual;
        if (mainVisual.length !== 0) {
          getData("photos/mainVisual", { id: mainVisual })
            .then((res) => {
              this.selectedPhotos = res.data.map((photo) => {
                return {
                  id: photo.id,
                  image: photo.image,
                  title: photo.title,
                };
              });
              // キャンセルボタンで元に戻す為
              this.backupSelectedPhotos = this.selectedPhotos;
            })
            .catch((err) => console.log(err));
        }
      });
    },
    selectPhotos(photo) {
      const selectedPhotos = this.selectedPhotos.slice();
      if (this.isSelected(photo.id)) {
        // selectedPhotosからクリア
        const index = selectedPhotos.findIndex((p) => p.id === photo.id);
        selectedPhotos.splice(index, 1);
      } else {
        // selectedPhotosに追加
        selectedPhotos.push(photo);
      }
      this.selectedPhotos = selectedPhotos;
    },
    save() {
      this.loading = true;
      this.loadingMessage = "設定中...";
      const setting = {
        mainVisual: this.selectedPhotos.map((photo) => {
          return photo.id;
        }),
      };
      updateData("clients/" + this.$store.getters["auth/me"].clientId, setting)
        .then((res) => {
          this.loading = false;
          this.loadingMessage = "";
          const count = res.data.setting.mainVisual.length;
          this.backupSelectedPhotos = this.selectedPhotos;
          this.$store.dispatch("snackbar/setSnackbar", {
            message: `${count}枚をメイン写真に設定しました。`,
            color: "success",
            timeout: 2000,
          });
        })
        .catch(() => {
          this.loading = false;
          this.loadingMessage = "";
        });
    },
    onCancel() {
      this.selectedPhotos = this.backupSelectedPhotos;
    },
    handlePreview() {
      this.isPreview = !this.isPreview;
    },
    handlePhotoPreview(photo) {
      this.previewPhoto = photo;
      this.isDialog = !this.isDialog;
    },
  },
};
</script>
